<template>
  <CompaniesList />
</template>

<script>
  export default {
    components: {
      CompaniesList: () => import('@/components/people/companies/CompaniesList')
    }
  }
</script>